


















































import { Vue, Prop, Component } from 'vue-property-decorator';

interface ICardDetails {
  key: string;
  title: string;
  unit: string;
  hint?: string;
  value: number;
  loading: boolean;
  secondaryValue?: number;
  secondaryPrefix?: string;
  secondarySuffix?: string;
  detailsCallback?: CallableFunction;
}

@Component({
  inheritAttrs: true,
})
export default class MultipleCardsPanel extends Vue {
  @Prop()
  title!: string;

  @Prop()
  path!: string;

  @Prop()
  iconTitle!: string;

  @Prop()
  iconDetail!: string;

  @Prop()
  iconColor!: string;

  @Prop()
  cards!: ICardDetails;
}
