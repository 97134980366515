








































































import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import { PaginationModel } from '@/models/pagination.model';
import { ISimpleTableColumn } from '@/interfaces/simple-table-column.interface';
import CardDataList from '@/components/card-data-list.vue';

@Component({
  components: {
    CardDataList,
  },
})
export default class AsyncCardDataList extends Vue {
  @VModel()
  dataListItems!: PaginationModel<unknown>;

  @Prop()
  readonly title!: string;

  @Prop()
  readonly iconTitle!: string;

  @Prop()
  readonly iconHeader!: string;

  @Prop()
  readonly messageHeader!: string;

  @Prop({ default: [] })
  readonly dataListColumns!: ISimpleTableColumn[];

  @Prop()
  readonly dataListItemKey!: string;

  @Prop()
  readonly loading!: boolean;

  @Prop({ default: false })
  readonly addActionOptions!: boolean;

  @Prop({ default: true })
  readonly addAction!: boolean;

  @Prop({ default: true })
  readonly editAction!: boolean;

  @Prop({ default: false })
  readonly flat!: boolean;

  @Prop({ default: true })
  readonly showTable!: boolean;

  @Prop()
  readonly accordion!: boolean;

  @Prop()
  onAddItem!: () => unknown;

  @Prop()
  onEditItem!: (item: unknown) => unknown;

  @Prop()
  onLoadMore!: () => unknown;

  expandedPanel = !this.accordion;

  get cardHeight(): string {
    let str = 'height: ';
    if (this.expandedPanel && this.accordion
      && this.dataListItems.results && this.dataListItems.results.length === this.dataListItems.total) {
      str += '272px;';
    } else if (this.expandedPanel && this.accordion
      && this.dataListItems.results && this.dataListItems.results.length !== this.dataListItems.total) {
      str += '236px;';
    } else if (this.expandedPanel && !this.accordion
      && this.dataListItems.results && this.dataListItems.results.length !== this.dataListItems.total
      && this.dataListItems.results.length > 10) {
      str += '358px;';
    } else if (this.expandedPanel && !this.accordion
      && this.dataListItems.results && this.dataListItems.results.length === this.dataListItems.total) {
      str += '394px;';
    } else {
      str += '112px';
    }
    return str;
  }

  loadMore(): void {
    this.expandedPanel = true;
    this.onLoadMore();
  }
}
